var config = {
  // Set the Demo Version
  demo: false,

  // googleAddsense
  // googleAddsense: "",

  //SEO Configurations
  appName: "G-Quiz",
  metaDescription: "G-Quiz is a Web Quiz Application",
  metaKeyWords: "G-Quiz,Quiz,Questions,Answers,Online Quiz",
  //API Configurations
  apiAccessKey: 8525,
  apiUrl: "https://quiz.g-quizapp.com/api/",

  //Language Configurations
  // Get Your Language Codes ---> https://developers.google.com/admin-sdk/directory/v1/languages
  supportedLanguages: ["en", "hi", "ur"],
  defaultLanguage: "en",

  // If your Default Language is not in supportedLanguages then add there first and after that set the defaultLanguage.

  //Quiz Configurations
  deductReviewAnswerCoins: 10, // 10 coins will be deducted if user Review the Answer
  deductIncorrectAnswerScore: 1, // This will deduct the points if user will give wrong answer
  deductLifeLineCoins: 1, // Deduct Coins when using Life Line

  // default country selection Configurations
  DefaultCountrySelectedInMobile: "GM", //Default Country Selected in Mobile Login Screen

  // guess the word Quiz Configurations
  Guessthewordhintcoin: 5, //deduct coins guess the word

  // 1 vs 1 battle Quiz Configurations
  // matchrandomBattleSeconds: 30,
  battlecorrectanswer: 4,
  randomBattleSeconds:30, // 1 vs 1 battle timer seconds
  Randombattlecoins: 5, //deduct coins 1 vs 1 battle
  randomBattleoneToTwoSeconds: 2, // quick answer :- 1 vs 1 battle points 2 added on first 1 & 2 seconds
  randomBattlethreeToFourSeconds: 1, // quick answer :- 1 vs 1 battle points 1 added on first 3 & 4 seconds

  //Firebase Configurations

  // apiKey: "XXXXXXXXXXXXXXXXXXXXX",
  // authDomain: "XXXXXXXXXXXXXXXXX",
  // projectId: "XXXXXXXXXXXXXXXXXX",
  // storageBucket: "XXXXXXXXXXXXXX",
  // messagingSenderId: "XXXXXXXXXX",
  // appId: "XXXXXXXXXXXXXXXXXXXXXX",
  // measurementId: "XXXXXXXXXXXXXX",


  apiKey: "AIzaSyDC0BGBKA0UmMYgDce0FU6YGo3G9UlbYXU",

  authDomain: "gamquiz.firebaseapp.com",

  databaseURL: "https://gamquiz.firebaseio.com",

  projectId: "gamquiz",

  storageBucket: "gamquiz.appspot.com",

  messagingSenderId: "646708695687",

  appId: "1:646708695687:web:856f14bd8b39e795f9de22",

  measurementId: "G-RBB7RKEJM2",

  //footer area
  companytext: "G-Quiz offers easy-to-understand lessons, homework help, practice tests, and learning reports to help students prepare for exams.",
  addresstext: "78 Atlantic Boulevard, Fajara, The Gambia.",
  phonenumber: "+2203480626",
  email: "info@g-quiz.com",
  facebooklink: "https://www.facebook.com/g-quiz",
  instagramlink: "https://www.instagram.com/g-quiz",
  linkedinlink: "https://www.linkedin.com/company/g-quiz",
  weblink: "https://g-quiz.com/",
  Video:"https://g-quizapp.com/",
  ebook: "https://g-quizapp.com/ebooks/",

  companyname: "G-QUIZ.",
};

export default config;
